.language-wrap
	@apply p-0  relative  flex 
	z-index: 121
	margin-right: r(24px)
	@media screen and ( max-width: 1200px)
		@apply  absolute-y  left-[15px] h-full
	&:hover 
		.language-list
			@apply  opacity-100 pointer-events-auto
	.current-language
		@apply relative z-40  start-item
		.icon
			display: flex
			height: 22px
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain
		em
			@apply text-dark font-normal leading-none ml-[6px]
			font-size: 12px
	.language-list
		@apply center-item absolute top-full left-0 opacity-0 pointer-events-none  bg-white col-ver
		transition: .4s all  ease-in-out
		li
			@apply px-2 leading-none
			@screen xl
				@apply px-1
		a
			@apply center-item 
			display: flex
			height: 32px
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain