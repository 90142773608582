//Type 1 first child icon
.modulepager
	@apply flex items-center justify-center pt-8 pb-0
	@media screen and ( max-width:1024px)
	padding-bottom: 30px
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			&:last-child()
			&.active
				a,span
					@apply bg-primary-900 text-white
			&:hover
				a,span
					@apply underline
			span,a
				width: 50px
				height: 50px
				text-decoration: none
				@apply  font-medium border-[2px] border-primary-900 center-item text-primary-900 leading-none bg-white font-bold rounded-full
				+fz(18px,20px)
				@media screen and ( max-width:1024px)
					height: 32px
					width: 32px
					
