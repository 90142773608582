footer
	@apply text-white relative
	padding-top: r(70px)
	background: url('../img/bg/footer-bg.jpg')
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	*
		@apply text-white
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full  h-full bg-primary-950 bg-opacity-80
	.container
		@apply relative z-50
	.logo
		@apply mx-auto
		padding-bottom: r(20px)
		@screen lg
			padding-bottom: r(32px)
		a
			@apply center-item
			display: flex
			height: r(80px)
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain
	.headline
		@apply mb-4
	address
		@apply not-italic
		*
			@apply not-italic
		li
			@apply start-item mb-4 font-medium
			+fz(16px,20px)
			align-items: flex-start
			*
				+fz(16px,20px)
				@apply font-medium
			em
				@apply mt-1 mr-2 leading-none font-black
				font-size: 20px
				
	ul
		li
			@apply font-medium mb-3
			+fz(16px,20px) 
			&.active,&:hover
				a
					@apply underline
			*
				@apply font-medium
				+fz(16px,20px)
	.social-wrap
		@media screen and ( max-width: 1024px)
			@apply center-item
		.ModuleContent
			@apply start-item
		a
			@apply center-item rounded-full overflow-hidden bg-secondary-400 border border-secondary-400 transition
			width: 36px
			height: 36px
			&:not(:last-child)
				@apply mr-2
			&:hover
				@apply bg-white
				em
					@apply text-secondary-400
			em
				@apply leading-none transition text-white
				font-size: 20px

	.copyright
		padding: r(50px) 0 r(20px)
		@apply text-center font-medium
		+fz(15px,18px)
		*
			@apply font-medium
			+fz(15px,18px)
