.home-service
	@apply overflow-hidden
	@screen xl
		height: calc( 100vh - 100px )
	.container-fluid
		@apply p-0 pointer-events-none bg-white
	.img
		@screen md
			border-right: 20px solid #F5F5F5
		a
			@apply pointer-events-auto overflow-hidden
			@screen xl
				display: flex
				height: calc( 100vh - 100px )
				width: auto
				img
					width: auto
					height: 100%
					object-fit: cover 
			@media screen and ( max-width: 1200px)
				+img-ratio(865,1150)
	.txt-hor
		@apply pointer-events-auto
		@media screen and ( max-width: 1024px)
			@apply py-8
		@screen md
			padding-left: r(80px)
		@screen lg
			padding-left: r(120px)
	.block-wrap
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none right-0 bottom-0 z-10  transition 
			width: r(500px)
			height: r(590px)
			background: url('../img/bg/dot.png')
			background-repeat: no-repeat
			background-size: 100% 100%
			background-position: center
	.container
		@screen md
			@apply absolute-center pointer-events-none
	.row
		@apply m-0 p-0
		.col
			@apply m-0 p-0
.swiper-vertical
	@apply relative
	@screen xl
		height: calc( 100vh - 100px )
	&.fixed-block
		@apply fixed left-0 right-0 bottom-0 
		top: 100px
		z-index: 500
	.swiper-wrapper
		@screen xl
			height: calc( 100vh - 100px )
	.swiper-slide
		@screen xl
			height: calc( 100vh - 100px )
			img
				transform: scale(1.0)
		&.swiper-slide-active
			img
				transition: 1s all  ease-in-out
				transform: scale(1.2)
	.nav-group
	
		@media screen and ( max-width: 1024px)
			@apply center-item justify-center w-fit mx-auto pt-5

		@screen lg
			@apply absolute-y right-[15px] z-40 col-ver
			z-index: 600
	.prev-icon,.next-icon
		em
			font-size: 20px
			@apply text-primary-950
			@media screen and ( max-width: 1024px)
				transform: rotate(-90deg)
	.swiper-pagination
		position: static !important
		@apply font-normal flex
		font-size: 18px !important
		font-family: 'Raleway', sans-serif !important
		*
			@apply font-normal mx-1
			font-size: 18px !important
			font-family: 'Raleway', sans-serif !important
			
.homepage
	overflow-y: scroll
	-ms-overflow-style: none
	scrollbar-width: none
	&::-webkit-scrollbar
		display: none
		-ms-overflow-style: none
		scrollbar-width: none