.primary-banner
	@apply relative overflow-hidden
	height: auto !important
	.banner-container
		@apply relative
		@media screen and ( max-width: 1200px)
			position: relative
			display: block
			height: 0
			overflow: hidden 
			padding-top: 550/750 *100%
			.swiper
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
	.txt-wrap
		@apply absolute-x text-left z-30 bottom-0
		padding-bottom: r(160px)
		@media screen and ( max-width: 1024px)
			padding-bottom: r(120px)
		@media screen and ( max-width: 576px)
			padding-bottom: r(90px)
			@apply w-full
	.wrap
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full 
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)
	.img
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 9.90%, rgba(0, 0, 0, 0.10) 56.25%, rgba(0, 0, 0, 0.50) 93.75%)
		@media screen and ( min-width: 1200.1px)
			a
				+img-ratio(860,1920)
		@media screen and ( max-width: 1200px)
			a
				+img-ratio(550,750)

	.title
		@apply font-bold leading-tight text-white mb-5 uppercase pb-4  relative uppercase
		+fz(24px,28px)
		*
			@apply font-bold leading-tight text-white 
			+fz(24px,28px)
		@media screen and ( max-width: 390px)
			+fz(20px,24px)
			*
				+fz(20px,24px)
		@screen sm
			+fz(32px,36px)
			*
				+fz(32px,36px)
		@screen xl
			@apply  pb-4
			max-width: 70%
			+fz(40px,44px)
			*
				+fz(40px,44px)
		&::after
			content: ''
			@apply block absolute left-0 pointer-events-none bottom-0 z-10  transition w-[180px] h-[2px] bg-white
	
	.desc
		@apply text-white  text-left
		+fz(15px,16px)
		*
			@apply text-white 
			+fz(15px,16px)
		@media screen and ( max-width: 390px)
			+fz(13px,14px)
			*
				+fz(13px,14px)
		@screen xl
			max-width: 70% 
		@media screen and ( max-width:576px )
			+fz(16px,20px)
			*
				+fz(16px,20px)
	.swiper-slide
		&.swiper-slide-active
			.title,.desc
				@apply opacity-0
				animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) .7s both
	.next-section
		height: r(44px)
		width: r(30px)
		background: url('../img/icon/mouse.svg')
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		@apply absolute-x  cursor-pointer pointer-events-auto
		z-index: 99
		bottom: r(30px)
	.prev,.next
		@media screen and ( max-width: 1024px)
			@apply absolute-y
		@media screen and ( max-width: 576px)
			@apply hidden
	.prev
		left: r(110px)
		@media screen and ( max-width: 1024px)
			left: r(20px)
	.next
		right: r(110px)
		@media screen and ( max-width: 1024px)
			right: r(20px)
	.swiper-nav
		@apply p-0