.home-about
	height: auto !important
	margin-top: r(80px)
	.zone-title
		@apply mb-2
	.sub-title
		@apply text-primary-600 text-center
		+fz(16px,20px)
		*
			+fz(16px,20px)
	.wrap-center
		@apply pt-6
		@screen lg
			@apply pt-8
	.text-clip
		@apply py-2 text-center uppercase font-black leading-none
		color: transparent
		background: url('../img/banner/1.jpg') 
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		-webkit-text-fill-color: transparent
		-webkit-background-clip: text
		background-attachment: fixed
		color: transparent
		+fz(64px,70px)
		*
			+fz(64px,70px)