.block-title
	@apply font-bold leading-[1.2] text-primary-900 mb-6
	+fz(28px,32px)
	*
		@apply font-bold leading-[1.2]  text-primary-900
		+fz(28px,32px)
	&.white
		@apply text-white
		*
			@apply text-white
	&.neutral
		@apply text-neutral-800
		*
			@apply text-neutral-800
	&.grey
		@apply text-neutral-800
		*
			@apply text-neutral-800
	&.line
		@apply relative pb-3 mb-3
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-[110px] h-[2px] bg-secondary-400
.zone-title
	@apply font-bold leading-[1.2] uppercase text-primary-900 mb-8
	+fz(36px,44px)
	*
		@apply font-bold leading-[1.2] uppercase text-primary-900
		+fz(36px,44px)
	&.white
		@apply text-white
		*
			@apply text-white
	&.neutral
		@apply text-neutral-800
		*
			@apply text-neutral-800
	@screen lg
		@apply mb-28
.headline
	@apply font-bold leading-[1.33] text-primary-900
	+fz(20px,24px)
	*
		@apply font-bold leading-[1.33] text-primary-900
		+fz(20px,24px)
	&.t-22
		+fz(18px,22px)
		*
			+fz(18px,22px)
	&.t-20
		+fz(16px,20px)
		*
			+fz(16px,20px)
	&.t-18
		+fz(15px,18px)
		*
			+fz(15px,18px)
	&.t-16
		+fz(13px,16px)
		*
			+fz(13px,16px)
	&:hover
		@apply underline
		*
			@apply underline
	&.white
		@apply text-white
		*
			@apply text-white
	&.grey
		@apply text-neutral-950
		*
			@apply text-neutral-950
		&:hover
			@apply text-primary-950
			*
				@apply text-primary-950
	&.normal
		@apply font-normal
		*
			@apply font-normal
.sub-title
	@apply font-bold leading-[1.33]
	+fz(20px,24px)
	*
		@apply font-bold leading-[1.33]
		+fz(20px,24px)
	&.white
		@apply text-white
		*
			@apply text-white
	&.t-20
		+fz(16px,20px)
		*
			+fz(16px,20px)
	&.t-18
		+fz(15px,18px)
		*
			+fz(15px,18px)
.eyebrow
	@apply leading-[1.33]
	+fz(13px,14px)
	*
		@apply leading-[1.33]
		+fz(13px,14px)
	&.t-24
		+fz(20px,24px)
		*
			+fz(20px,24px)
	&.t-22
		+fz(18px,22px)
		*
			+fz(18px,22px)
	&.t-20
		+fz(16px,20px)
		*
			+fz(16px,20px)
	&.t-18
		+fz(15px,18px)
		*
			+fz(15px,18px)
	&.t-16
		+fz(13px,16px)
		*
			+fz(13px,16px)
.block-title,.zone-title,.headline,.sub-title,.eyebrown
	@apply transition
	*
		@apply transition