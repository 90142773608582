.contact-us
	address
		@apply not-italic
		color: #333
		*
			@apply not-italic
			color: #333
		li
			@apply start-item mb-4 font-normal 
			+fz(14px,16px)
			align-items: flex-start
			color: #333
			@screen lg
				@apply mb-5
			*
				+fz(14px,16px)
				@apply font-normal
				color: #333
			em
				@apply mr-4 leading-none font-black text-primary-900
				font-size: 20px
				@screen lg
					@apply mr-5
			span,p
				@apply mb-0 my-auto

	.block-wrap
		@apply overflow-hidden border-[10px] border-primary-900 rad-5
		background: #f6f6f6
		padding: r(50px) r(26px)
	.logo
		@apply mb-5
		@screen lg
			@apply mb-8
	.wrap-form
		.frm-btnwrap
			@media screen and ( max-width: 576px)
				@apply static 
				margin-top: 0 !important
.map-wrapper 
	@apply h-full w-full
	.ModuleContent
		display: block
		height: 100%
		@apply overflow-hidden rad-5
		text-align: center
		width: 100%
		@screen lg
			height: 540px
		@media screen and ( max-width: 1024px)
			height: 400px
		@media screen and ( max-width: 576px)
			height: 300px
		iframe
			@apply inline-block w-full h-full