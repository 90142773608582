.desc,.zone-desc
	@apply  leading-[1.5] font-medium text-neutral-950
	+fz(14px,16px)
	*
		@apply  leading-[1.5] font-medium text-neutral-950
		+fz(14px,16px)
	&.t-24
		+fz(20px,24px)
		*
			+fz(20px,24px)
	&.t-22
		+fz(18px,22px)
		*
			+fz(18px,22px)
	&.t-16
		+fz(13px,16px)
		*
			+fz(13px,16px)
	&.t-14
		+fz(13px,14px)
		*
			+fz(13px,14px)
	&.white
		@apply text-white
		*
			@apply text-white
.desc,.zone-desc
	@apply transition
	*
		@apply transition