main
	padding-top: 100px
	@media screen and ( max-width: 1024px)
		padding-top: 60px
.four-swiper,.trinity-swiper,.single-swiper,.double-swiper
	@apply relative
.main-menu-toggle
	@apply hidden
	@media screen and ( max-width:  1024px)
		@apply flex items-center pl-8
	@media screen and ( max-width:  768px)
		@apply pl-4
.frm-thanks
	@apply my-16 p-8 text-center
	margin: 0 auto
	width: 500px
	h3,h4,h5
		@apply text-primary-900 font-bold text-red text-16 mb-3
	p,span
		@apply text-14  font-normal
	@media screen and ( max-width: 1024px)
		width: 320px
.tab-item
	@apply hidden
	&.active
		@apply block
.edit-link
	width: 30px
	height: 30px
	pointer-events: auto
	.fa-pencil
		width: 18px
		height: 18px
		background-size: contain
		background-repeat: no-repeat
		background-position: center
		background-image: url("data:image/svg+xml;charset=utf-8,<svg  viewBox='0 0 18 18'  xmlns='http://www.w3.org/2000/svg'><path d='M6.35473 15.7485L2.27202 11.6658L12.1468 1.79106L16.2295 5.87378L6.35473 15.7485ZM1.89039 12.385L5.63556 16.1302L0.0205078 18L1.89039 12.385ZM17.4852 4.62344L16.7771 5.33159L12.6889 1.24347L13.3971 0.535326C14.1104 -0.178442 15.2672 -0.178442 15.9805 0.535326L17.4852 2.04001C18.1934 2.75572 18.1934 3.90795 17.4852 4.62344Z' fill='#094594'/></svg>")
.custom-select
	@apply relative w-fit
	&::after
		content: '\f0d7'
		font-family: 'Font Awesome 6 Pro'
		@apply block absolute-y pointer-events-none right-[20px] z-10  transition leading-none font-bold
		height: 16px
		width: 8px
		font-size: 14px
	select
		-webkit-appearance: none
		-moz-appearance: none
		height: 48px
		@apply rounded-[4px]  bg-white
		border: 1px solid #e5e5e5
		min-width: 144px
		padding: 4px 60px 4px 20px
		@media screen and ( max-width: 1024px)
			height: 40px
.scollbar-wrap
	height: 300px
	padding-right: 15px
	overflow-y: auto
	scrollbar-width: thin
	scrollbar-color: #A0C4F4 #cccccc
	&::-webkit-scrollbar
		background: #cccccc
		width: 3px
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 6px #cccccc
		background-color: #cccccc
	&::-webkit-scrollbar-thumb
		background-color: #A0C4F4
	@media screen and ( max-width:1024px)
		height: auto
.qradio
	input
		background-repeat: no-repeat
		background-position: center
		appearance: none
		@apply block
		width: 20px
		height: 20px
		background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
		&:checked
			background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff0000' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
.table-wrap
	@media screen and ( max-width:1024px)
		overflow-x: auto
	table
		@apply w-full
		@media screen and ( max-width:1024px)
			white-space: nowrap
		tr
			td
				@apply px-5 py-2
				&:first-child
					@apply font-bold pl-0