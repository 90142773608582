header
	height: 100px
	@apply fixed top-0 left-0 right-0  bg-white w-full
	z-index: 121
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10)
	@media screen and ( max-width: 1200px)
		height: 60px
	.container
		@apply flex justify-between h-full
		@media screen and ( max-width: 1200px)
			@apply justify-end
.nav-brand
	@apply z-20 start-item
	@media screen and ( max-width: 1200px)
		@apply absolute-center
	a
		display: flex
		height: 50px
		width: auto
		@media screen and ( min-width: 1200px)
			height: 80px
		img,svg
			width: 100%
			height: 100%
			object-fit: contain
.nav-primary-menu
	@media screen and ( max-width: 1200px)
		@apply hidden p-6
	.nav
		@apply gap-item
		@media screen and ( min-width: 1200px)
			@apply h-full
		@media screen and ( max-width: 1200px)
			@apply col-hor w-full
	.drop-down
		@media screen and ( max-width: 1200px)
			@apply flex flex-col
		&.is-open
			.title
				em
					&:before
						transform: scaleY(-1)
		.nav-link-sub
			@apply start-item 
			&.active,&.current-project-ancestor
				a
					@apply underline
			a
				@apply normal-case font-medium  py-2  px-0
				justify-content: flex-start
				font-size: clamp(16px,r(18px),18px)
				@media screen and ( max-width: 1200px)
					@apply p-4 text-neutral-900 py-2
				&:hover
					@apply underline
		.title
			@apply relative
			@media screen and ( max-width: 1200px)
				@apply gap-item w-full 
			@media screen and ( min-width: 1200px)
				@apply center-item p-0
			a
				@apply  relative text-primary-900 transition
				padding: r(8px) 0
				@media screen and ( max-width: 1200px)
					@apply py-3
			
			em
				@apply hidden
				@media screen and ( max-width: 1200px)
					width: 40px
					height: 30px
					font-size: 20px
					@apply  absolute right-0 top-50 translate-y-50 text-white leading-none center-item transition text-primary-900
					margin-left: r(10px)
					font-size: 16px
					&:before
						@apply transition
		@media screen and ( min-width: 1200px)
			&:hover
				.nav-sub
					@apply opacity-100 pointer-events-auto transform-none
		.nav-sub
			border-radius: 0 0 8px 8px
			@media screen and ( max-width: 1200px)
				@apply hidden  w-full
			@media screen and ( min-width: 1200px)
				padding: r(10px)  r(25px)
				@apply absolute top-full left-0 w-max z-20 opacity-0 pointer-events-none border-none bg-white
				transform: translateY(25%)
				transition: .45s all  ease-in-out
				+boxshadow
	.nav-link
		@apply relative z-10 
		@media screen and ( max-width: 1200px)
			@apply  w-full start-item 
		@media screen and ( min-width: 1200px)
			margin: 0 r(20px)
			@apply h-full  center-item
		@media screen and ( min-width: 1600px)
			margin: 0 r(32px)
		&.active,&:hover,&.current-project-ancestor
			.title
				a,em
					@apply text-secondary-500
			>a
				@apply text-secondary-500
		>a
			@media screen and ( max-width: 1200px)
				@apply py-3
			@media screen and ( min-width: 1200px)
				padding: r(8px) 0
		a
			@apply center-item leading-none font-medium relative uppercase text-primary-900 transition
			+fz(18px,24px)
			@media screen and ( max-width: 1200px)
				@apply font-semibold 
			@media screen and ( min-width: 1200px)
				+fz(15px,18px)
.site-menu-toggle
	@apply  center-item items-center h-full mt-1 ml-5
	@screen xl
		@apply hidden
.header-wrap-right
	@apply h-full end-item
.mobile-nav-wrap
	@apply fixed top-[60px] left-0 w-full h-full bg-white z-[122]
	+boxshadow
	width: 320px
	height: calc(100vh - 60px)
	transform: translateX(-100%)
	transition: transform .8s cubic-bezier(.165,.84,.44,1)
	@media screen and ( min-width:1200px )
		@apply hidden 
	&.is-open
		transform: none