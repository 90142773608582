.project-detail
	.table-wrap
		@apply pt-4
		table
			@apply whitespace-normal
			width: 100% !important
		td
			@apply text-neutral-950 font-medium whitespace-normal align-baseline
			+fz(14px,16px)
			*
				@apply text-neutral-950 font-medium whitespace-normal
				+fz(14px,16px)
	.first-col
		@media screen and ( max-width: 1024px)
			@apply order-2
	
	.project-main
		@apply relative
		.item
			@apply relative
		.img
			@apply relative
			a
				@screen lg
					display: flex
					height: r(730px)
					width: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
				@media screen and ( max-width: 1024px)
					+img-ratio(550/980)
	.project-thumb
		@apply my-auto relative
		@media screen and ( max-width: 1024px)
			@apply px-8
		@screen lg
			padding:  r(80px) 0
		.swiper-wrapper
			@apply pl-2 relative z-40
		.swiper
			@screen lg
				height: r(560px)
		.img
			@apply w-full
			a
				display: flex
				width: 100%
				height: r(120px)
				img
					width: 100%
					height: 100%
					object-fit: cover
		.item
			@apply relative border-2 border-transparent
			@media screen and ( max-width: 1024px)
				@apply center-item h-full w-full
		.swiper-slide
			&.swiper-slide-thumb-active
				.item
					@apply border-secondary-400
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
	.nav-prev,.nav-next
		@apply z-50
		@media screen and ( max-width: 1024px)
			@apply absolute-y
			transform: translateY(-50%) rotate(-90deg)
		@screen lg
			@apply absolute-x 
		em
			@apply text-secondary-500 leading-none 
			font-size: 36px
			@media screen and ( max-width: 1024px)

				font-size: 32px
	.nav-prev
		@media screen and ( max-width: 1024px)
			@apply left-0
		@screen lg
			@apply top-0
	.nav-next
		@media screen and ( max-width: 1024px)
			@apply right-0
		@screen lg
			@apply bottom-0
.other-project
	.swiper
		@apply pb-2