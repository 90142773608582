.home-project
	.block-wrap
		@apply relative
	.block-title
		@apply mb-2
	.single-swiper 
		@apply relative z-50
	.container-fluid
		@apply absolute-center w-full h-full z-10 bg-white
		@media screen and ( max-width: 1024px)
			@apply hidden
		.row,.col
			@apply m-0 p-0 bg-white h-full
		.col
			&:nth-child(2)
				background: url('../img/bg/project-bg.png')
				background-repeat: no-repeat
				background-size: cover
				background-position: center
	.container
		@apply p-8
		.col
			@media screen and ( max-width: 1024px)
				&:first-child
					@apply order-2
	.img
		a
			+img-ratio(564,750)
	.zone-title
		@apply mb-1
		@screen lg
			@apply mb-4
	.eyebrow
		@apply text-primary-900
	.prev
		@screen lg
			left: -90px
	.next
		@screen lg
			right: -90px
	.txt-hor
		@media screen and ( max-width: 1024px)
			@apply col-ver text-center
	.item
		.zone-title
			@apply mb-0
	