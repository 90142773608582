.news-list
.news-item-big
	@apply m-0 p-0
	.col
		@apply m-0 p-0
	.txt-hor
		padding: r(30px)
		@media screen and ( max-width: 576px)
			@apply px-0
	.img
		a
			+img-ratio(560,750)
	time
		@apply py-1
	.desc
		+line(3)
.news-item 
	.txt
		@apply p-5 pb-0
		@media screen and ( max-width: 576px)
			@apply px-0
		@screen lg
			@apply p-6 pb-0
	time
		@apply mb-1
	.headline
		a
			+line(2)