.about-1
	background: linear-gradient(140deg, #ABC7ED 0%, #BCD3F2 38.45%, #D4E4F7 68.84%, #C5D9F0 100%)
	.row
		@apply m-0 p-0
		.col
			@apply m-0 p-0
	.zone-title
		@apply mb-3
	.img
		a
			display: flex
			height: r(530px)
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain
.about-2
	.zone-title
		@apply mb-2
	.headline
		@apply text-primary-400 mb-5
		&:hover
			@apply no-underline
		@screen lg
			@apply mb-7
	.item
		padding: r(40px) r(20px)
		@apply h-full
	.col
		&:first-child
			.item
				@apply bg-primary-800
		&:nth-child(2)
			.item
				@apply bg-secondary-500
			
.about-3
	background: url('../img/bg/about-bg-2.png')
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	padding: r(80px) 0 r(60px)
	.zone-title
		@apply mb-8
		@screen sm
			@apply mb-10
		@screen lg
			margin-bottom: r(60px)
	.block-wrap
		@apply relative z-10
		@screen lg
			background: url('../img/icon/half-circle.svg')
			background-repeat: no-repeat
			background-size: 100% 100%
			background-position: center
			height: r(450px)
	.carousel-txt-wrap
		@apply flex flex-wrap
		@screen lg
			@apply absolute-x bottom-0
	.circle-carousel
		@apply static flex justify-between
		@media screen and ( max-width: 1024px)
			@apply hidden
	.item
		@apply absolute z-40
		&.is-active
			.icon
				&::before,&::after
					@apply opacity-100
				a
					@apply bg-secondary-400
		.icon
			@apply  rounded-full overflow-hidden center-item relative
			width: r(192px)
			height: r(192px)
			min-width: r(192px)
			&::before,&::after
				content: ''
				@apply block absolute-center pointer-events-none  z-10  transition opacity-0
				background-repeat: no-repeat !important
				background-size: 100% 100% !important
				background-position: center !important
			&:before
				width: r(192px)
				height: r(192px)
				background: url('../img/bg/yl-border.svg')
			&:after
				width: r(158px)
				height: r(158px)
				background: url('../img/bg/yl-bg.svg')
			a
				width: r(110px)
				height: r(110px)
				min-width: r(110px)
				@apply center-item relative z-50 bg-primary-900
				img
					width: r(60px)
					height: r(60px)
					object-fit: contain
		&:nth-child(1)
			@apply bottom-0
			@screen lg
				left: r(-30px)
			@screen xl
				left: r(-90px)
		&:nth-child(2)
			@apply top-0
			@screen lg
				left: r(110px)
			@screen xl
				left: r(90px)
		&:nth-child(3)
			@apply top-0
			@screen lg
				right: r(110px)
			@screen xl
				right: r(90px)
		&:nth-child(4)
			@apply  bottom-0
			@screen lg
				right: r(-30px)
			@screen xl
				right: r(-90px)

			
		&:nth-child(2),&:nth-child(3)
			@apply  top-0  z-30
		a
			@apply rounded-full overflow-hidden
	.carousel-txt
		@media screen and ( max-width: 1024px)
			@apply w-1/2  px-4
		@media screen and ( max-width: 576px)
			@apply w-full mb-4
		@screen lg
			@apply hidden
			&.is-active
				@apply block
		.img
			@media screen and ( max-width: 1024px)
				a
					@apply mx-auto p-4 bg-primary-900 rounded-full overflow-hidden mb-3
					display: flex
					height: r(90px)
					width: r(90px)
					min-width: r(60px)
					img
						width: auto
						height: 100%
						object-fit: contain
			@screen lg
				@apply hidden
	.block-title
		@apply mb-1
	.icon
.about-4
	.img
		a
			+img-ratio(430,304)

