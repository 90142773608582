.project-list
.project-item-big
	@apply m-0 p-0
	.col
		@apply m-0 p-0
	.img
		a
			+img-ratio(480,640)
	.txt-hor
		background:  url('../img/bg/project-bg.png')
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		padding: r(30px) 
		@screen lg
			padding: r(30px) r(60px)
	.block-title
		@apply mb-0
	.headline
		@apply font-normal
		*
			@apply font-normal
.project-item
	@apply overflow-hidden rad-4
	.img
		a
			+img-ratio(312,416)
	.txt
		padding: 20px 10px
		@apply border border-primary-200 border-t-0 
		border-radius: 0 0 16px 16px
	.desc
		+line(3)