.recruit-detail
	.gap-wrap
		@media screen and ( max-width: 576px)
			@apply col-hor
		.block-title
			@apply mb-0

	.block-wrap
		@apply w-full mb-8
		.headline
			@apply mb-3 text-neutral-950
			@screen lg
				@apply mb-4
		ol,ul
			@apply flex flex-wrap w-full
			li
				@apply start-item text-neutral-950 font-medium mb-2 w-full
				align-items: flex-start
				+fz(14px,16px)
				*
					@apply text-neutral-950 font-medium
					+fz(14px,16px)
				@screen md
					@apply w-1/2  
				.icon
					display: flex
					height: 18px
					width: auto
					@apply mr-3 
					img
						width: auto
						height: 100%
						object-fit: contain
	.apply-form
		@apply start-item pt-5
		@screen lg
			@apply pt-7
		@media screen and ( max-width: 576px)
			@apply col-ver
			.btn
				@apply w-full
			.btn-primary
				@apply w-full
		.or-text
			@apply px-4 text-neutral-950 font-medium py-2
			+fz(14px,16px)
			@screen lg
				@apply px-7
	address
		@apply font-medium not-italic
		+fz(14px,16px)
		*
			@apply font-medium not-italic
			+fz(14px,16px)
		li
			&:not(:last-child)
				@apply mb-3
	.other-recruit-wrap
		
	.recruit-item
		&:not(:last-child)
			@apply mb-5
		time,.desc
			+fz(13px,14px)
		em
			font-size: 16px
		time
			@apply mb-0
		.txt
			@apply py-1 px-4
			@media screen and ( max-width: 576px)
				@apply py-4
		.headline
			@apply pt-1
			a
				+line(2)
		.img
			min-width: 160px
			a
				min-width: 160px
				height: 120px
.fullcontent
	@apply font-medium text-neutral-950 leading-normal
	+fz(15px,16px)
	*
		@apply font-medium text-neutral-950 leading-normal
		+fz(15px,16px)
	img,iframe
		@apply block mx-auto my-4
	p
		@apply mb-3
	ul,ol
		list-style-type: disc
		li
			@apply mb-2 start-item
			align-items: flex-start
			&::before
				content: ''
				@apply block  pointer-events-none mt-2 mr-3 z-10  transition w-[8px] h-[8px] bg-primary-950
				transform: rotate(45deg)
				min-width: 8px

	h2,h3,h4,h5
		@apply text-neutral-950 mb-4 font-bold #{!important} 
	strong
		font-weight: 700 !important
		*
			font-weight: 700 !important
	h2
		+fz(32px,36px)
		@media screen and ( max-width: 576px)
			+fz(26px,28px)
	h3
		+fz(28px,32px)
		@media screen and ( max-width: 576px)
			+fz(24px,28px)
	h4
		+fz(24px,28px)
		@media screen and ( max-width: 576px)
			+fz(20px,24px)
	h5
		+fz(16px,18px)
		@media screen and ( max-width: 576px)
			+fz(16px,20px)
	.row
		@apply mb-0
.social-wrap
	@apply end-item items-start
	@media screen and ( max-width: 576px)
		@apply pt-5
	span
		@apply mr-4 text-neutral-950 font-medium
	a
		@apply center-item border rounded-full overflow-hidden border-primary-900
		width: 32px
		height: 32px
		&:not(:last-child)
			@apply mr-3
		em
			@apply text-primary-900 leading-none
			font-size: 16px