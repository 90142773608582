.swiper-grid-section
	.grid-swiper
		height: 300px
	.swiper
		width: 100%
		height: 100%
		margin-left: auto
		margin-right: auto
	.swiper-slide
		height: calc((100% - 30px) / 2) !important
	.item
		@apply py-10  h-full text-center center-item
.pad-10
	padding: r(60px) 0
	@screen xl
		padding: r(100px) 0 
.pad-t-10
	padding-top: r(60px)
	@screen xl
		padding-top: r(100px) 
.pad-b-10
	padding-bottom: r(60px)
	@screen xl
		padding-bottom: r(100px) 
.pad-8
	padding: r(60px) 0
	@screen xl
		padding: r(70px) 0 
.pad-6-8
	padding: r(60px) 0
	@screen xl
		padding: r(60px) 0  r(80px)
.pad-t-8
	padding-top: r(60px)
	@screen xl
		padding-top: r(80px) 
.pad-b-8
	padding-bottom: r(60px)
	@screen xl
		padding-bottom: r(80px) 
.pad-6
	padding: r(60px) 0
	@screen xl
		padding: r(60px) 0 
.pad-t-6
	padding-top: r(60px)
	@screen xl
		padding-top: r(60px) 
.pad-b-6
	padding-bottom: r(60px)
	@screen xl
		padding-bottom: r(60px) 
