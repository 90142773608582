.global-breadcrumb
	@apply  flex justify-start items-center py-2
	min-height: 50px
	box-shadow: 5px 5px 25px 0px #0000000F
	ol
		@apply p-0 list-none
		li
			@apply inline-flex relative leading-none
			padding:  0 14px
			&:after
				content: '|'
				@apply  block font-light absolute-y pointer-events-none z-10 left-full text-brand-5B5A5A
				font-size: 16px
				height: 16px
				width: 12px
				@media screen and ( max-width: 1024px)
					font-size: 14px
			
			&:last-child
				&:after
					@apply hidden
			a,span
				font-size: 16px
				@apply  font-normal text-brand-5B5A5A
				&.active
					@apply text-brand-E79632