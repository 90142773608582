.btn
	span,em
		@apply relative z-50
		transition: .4s all  ease-in-out
	&.btn-primary
		@apply border  relative text-white border-secondary-400 bg-secondary-400 rounded-full
		padding: 6px r(24px)
		height: r(44px)
		min-height: 40px
		&::before
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-0 h-full  bg-white
		&:hover
			&::before
				@apply w-full
			span,em
				@apply text-secondary-400
		span
			@apply uppercase font-medium relative z-40
			+fz(14px,16px)
		em
			font-size: 16px
			@apply leading-none text-white ml-4
		&.blue
			@apply bg-primary-900 border-primary-900
			&:hover
				span,em
					@apply text-primary-900
		&.blue-2
			@apply bg-white border-primary-900
			&:before
				@apply bg-primary-900
			span,em
				@apply text-primary-900
			&:hover
				span,em
					@apply text-white
		&.white
			@apply bg-white
			&:before
				@apply bg-secondary-400
			span,em
				@apply text-secondary-400
			&:hover
				span,em
					@apply text-white
				
	&.btn-secondary
		@apply pr-4
		span,em
			@apply font-bold
		span
			@apply  uppercase
		em
			@apply ml-3
		&:hover
			span,em
			em
				transform: translateX(3px)
	&.btn-tertiary
		span,em
.prev,.next
	@apply z-10 transition absolute-y cursor-pointer center-item  transition  leading-none 
	width: r(68px)
	height: r(68px)
	@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
		width: 40px
		height: 40px
		line-height: 40px
	@media screen and ( max-width:  1024px)
		@apply static mx-6
		transform: none
		width: 36px
		height: 36px
		line-height: 36px
	&.swiper-button-disabled
		@apply opacity-50
	&:hover
		@apply bg-primary-500
		em
			@apply text-white
	em
		@apply inline-block transition font-light leading-none text-white
		font-size: 32px
		@media screen  and ( max-width: 1440px)
			font-size: 24px
.swiper-nav
	@media screen and ( max-width: 1024px)
		@apply center-item pt-8
	&.orange
		.prev,.next
			@apply text-secondary-400 opacity-100
			em
				@apply text-secondary-400 opacity-100
			&:hover
				@apply bg-secondary-400
				em
					@apply text-white
		.next
			right: -80px
			@media screen and ( min-width: 1024px) and ( max-width: 1440px)
				right: -60px
		.prev
			left: -80px
			@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
				left: -60px
	&.normal
		padding: 0 !important
		margin: 0 !important
		.next,.prev
			left: unset !important
			right: unset !important
			position: relative !important
			transform: none !important
		.prev
			margin-right: 16px
	&.inset
		.next
			right: 0
		.prev
			left: 0
	&.white
		.next,.prev
			@apply border-white
			em
				@apply text-white

	.next
		right: -90px
		@media screen and ( min-width: 1024px) and ( max-width: 1440px)
			right: -60px
	.prev
		left: -90px
		@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
			left: -60px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply justify-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
.txt-hor
	@apply h-full col-hor