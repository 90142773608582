.recruit-1
	.img
		a
			+img-ratio(480,640)
.recruit-2
	.block-wrap
		padding: r(120px) 0
		@apply overflow-hidden rad-5 relative col-ver
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full bg-dark opacity-60
		.block-title,.desc
			@apply relative z-40
	.desc
		@apply text-center
		@screen lg
			@apply w-10/12 mx-auto
	.block-title
		@apply mb-1
.recruit-3
	.row
		@media screen and ( max-width: 576px)
			margin: 0 -5px -10px
			.col
				padding: 0 10px 
				margin-bottom: 10px
	.item
		@apply rad-5  bg-white col-ver overflow-hidden p-5 h-full
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
		justify-content: flex-start
		@screen lg
			@apply p-8
	.icon
		display: flex
		height: r(50px)
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain
	.txt
		@apply text-center
	.headline
		@apply pt-3 pb-1
.recruit-item
	@apply overflow-hidden rad-2 
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
	@screen sm
		@apply start-item
	.img
		@media screen and ( max-width: 576px)
			+img-ratio(170,225)
		@screen sm
			min-width: 230px
			a
				display: flex
				height: 170px
				width: auto
				min-width: 230px
				img
					width: auto
					height: 100%
					object-fit: cover
	.txt
		@apply col-hor p-6
		@screen sm
			@apply h-full  
		@screen lg
			@apply pt-8
	.headline
		@apply pt-2 mb-2
	.desc
		em
			@apply mr-2
			font-size: 18px