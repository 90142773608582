.search-page
	padding: 70px 0
	h1
		letter-spacing: -2px
		margin-bottom: 20px
		font-size: 45px
		line-height: 55px
		font-weight: 500
		color: #333333
		@media (max-width: 992px)
			font-size: 24px
			line-height: 30px
		&.center
			text-align: center
	.btn-reindex
		display: none
	.searchcontrols
		.form-inline
			position: relative
			&:before
				position: absolute
				content: "\f002"
				font-family: 'Font Awesome 6 Pro'
				top: 0
				right: 10px
				z-index: 1
				font-size: 24px
				color: #4285f4
				width: 44px
				height: 44px
				display: flex
				align-items: center
				justify-content: center
				pointer-events: none
		.frm-btn
			position: absolute
			top: 0
			right: 10px
			background-color: rgba(0,0,0,0)
			z-index: 2
	input[type="text"]
		width: 100%
		height: 100%
		padding-left: 20px
		padding-right: 54px
		border: 1px solid #dfe1e5
		background-color: #fff
		color: #333
		font-size: .875rem
		font-weight: 400
		height: 44px
		border-radius: 22px
		box-shadow: 0 0 0 0px rgba(0,0,0,0)
		transition: .25s cubic-bezier(.4,0,.2,1)
		outline: none
		&:focus
			box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28)
			border-color: rgba(223,225,229,0)
	input[type="submit"]
		border-radius: 2px
		user-select: none
		background-color: rgba(0,0,0,0)
		border: 0
		box-shadow: none
		cursor: pointer
		text-align: center
		display: flex
		align-items: center
		justify-content: center
		height: 40px
		outline: none
		width: 44px
		height: 44px
		border-radius: 50%
		font-size: 0
	.text-danger
		margin-top: 20px
		font-size: 15px
		color: #333
		font-style: italic
		font-weight: 600
	.searchresultsummary
		margin-bottom: 20px
	.searchresults
		margin-top: 30px
		.modulepager
			&:first-child
				display: none
	.searchresultlist
		margin-bottom: 30px
	.searchresult
		margin-bottom: 30px
		box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28)
		padding: 16px
		border-radius: 8px
		&:last-child
			margin-bottom: 0
		h3
			font-size: 18px
			line-height: 1.33
			font-weight: 400
			margin-bottom: 10px
			a
				color: #1A0DAB
				text-decoration: none
				font-weight: 500
				// &:hover
				// 	text-decoration: underline
		.searchresultdesc
			color: #545454
			line-height: 1.54
			word-wrap: break-word
			font-size: small
			font-weight: 400
			margin-bottom: 10px
		.searchterm
			color: #6a6a6a
			font-weight: bold
			font-style: normal
			line-height: 1.54
			word-wrap: break-word
			font-size: small
		hr
			display: none !important
.searchresults
	.form-group
		input[type='submit']
			@apply absolute top-0 right-0
.search-wrap
	@apply flex items-center
	@media screen and ( max-width: 1200px)
		@apply mr-0
.search-toggle
	em
		@apply  leading-none font-light text-primary-900
		font-size: 20px
		@media screen and ( max-width: 576px)
			font-size: 20px
.searchbox
	@apply fixed pointer-events-none  left-50 translate-x-50  opacity-0 transition w-full  flex  bg-white justify-center items-center
	transition: .5s all ease-in-out
	top: 60px
	box-shadow:  0 0 0.625rem 0 rgba(0, 0, 0, 0.1)
	height: 25vh
	@media screen and ( min-width: 1200px)
		top: 100px
	.container
		@apply mx-auto center-item
		@media screen and ( max-width: 1200px)
			padding: 0 !important
	.search-wrapper-bg
		position: relative
		display: flex
		@apply w-full bg-white 
		+boxshadow
	.svg-magnify
		width: 18px !important
		height: 18px !important
	&.open
		z-index: 20
		@apply pointer-events-auto opacity-100
	input[type="text"]
		background: rgba(0, 0, 0, 0)
		border: 0px
		@apply  relative  w-full font-normal
		z-index: 99
		font-size: 20px
		margin: 0 auto
		outline: none
		text-align: left
		display: flex
		padding-right: 60px
		height: 40px
		padding-left: 30px
		&:focus
			box-shadow: 0 0.0625rem 0.375rem 0 rgba(32,33,36,0.47)
		&::placeholder
			@apply text-[16px]  text-left
	button
		@apply center-item h-full
		position: absolute
		text-align: center
		top: 0
		right: 0
		display: flex
		width: 40px
		height: 40px
		em
			color: #333
			font-size: 20px
			z-index: 99
			font-size: 26px
