.home-partner
	.block-wrap
		@apply bg-white py-10
	.partner-swiper
		@screen xl
			height: r(230px)
		.swiper
			width: 100%
			height: 100%
			margin-left: auto
			margin-right: auto
	.img
		a
			@apply center-item
			height: r(100px)
			width: 100%
			img
				width: auto
				height: 100%
				object-fit: contain
	.swiper-slide
		@screen xl
			height: calc((100% - 30px) / 2) !important
