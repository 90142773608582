=img-ratio($h:1,$w:1,$fit:cover)
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: $h / $w * 100%
	img
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: $fit
		transition: .3s ease-in-out all
=boxshadow
	box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09)