.home-network
	.logo
		@apply mx-4
		@screen lg
			@apply mx-7
		a
			@apply center-item
			height: r(60px)
			width: 100%
			img
				width: auto
				height: 100%
				object-fit: contain
	.img
		a
			@apply center-item
			height: r(500px)
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain