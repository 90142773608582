.shopping-cart-toggle
	@apply flex items-center justify-center  cursor-pointer static
	@screen lg
		@apply relative ml-0
	@media screen and ( min-width: 1600px)
		margin-left: r(30px)

	.ModuleContent
		@apply flex items-center justify-center 
	.cart-header
		@apply flex
	.cart-icon
		@apply center-item flex-col relative
		em
			font-size: 20px
			height: 20px
			@apply text-white transition leading-none font-light
		.cart-number
			@apply center-item rounded-full  absolute font-bold 
			right: -7px
			top: -5px
			transition: .3s all  ease-in-out
			width: 14px
			height: 14px
			@media screen and ( max-width: 1024px)
				right: -10px
			.quantity
				@apply font-bold
				transition: .3s all  ease-in-out
				font-size: 10px
					
.shopping-cart-wrap
	@apply py-5 px-5 bg-white right-0 z-50 opacity-0 pointer-events-none z-0 transition absolute-x top-full
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05)
	width: 370px
	@media screen and ( max-width: 576px)
		width: 320px
	&:hover
		cursor: pointer
	&.open
		@apply pointer-events-auto opacity-100 z-50
	.remove-item
		@apply absolute  right-0 top-0
		@apply flex flex-col justify-center items-center text-red
		*
			@apply text-center
		.text
			@apply font-medium mb-4
			font-size: clamp(14px,r(16px),16px)
		.btn-primary
			em
				height: 14px !important
				line-height: 14px !important
				@apply inline-block  mr-2
			&:hover

				em,span
					@apply text-red
					transform: none !important
				span
					color: red !important
	.close-cart
		position: absolute
		top: 0
		right: 0
		z-index: 50
		padding: 0 6px 6px 6px
		em
			color: #e52322
			height: 20px
			font-size: 20px
			line-height: 20px
	.cart-quantity
		em
			height: 18px
			font-size: 18px
			line-height: 18px
	.head
		border-bottom: 3px solid #ebebeb
		@apply flex justify-between items-center w-full pb-3
	.cart-quantity
		@apply flex
		.amount
			@apply px-1  font-semibold
			font-size: clamp(12px,r(15px),15px)
	.body
		@apply w-full pb-8 pt-4
		.scroll-wrapper
			height: 330px
			padding-right: 15px
			overflow-y: auto
			scrollbar-width: thin
			scrollbar-color: red #cccccc
			@apply leading-relaxed text-justify
			&::-webkit-scrollbar
				background: #cccccc
				width: 3px
			&::-webkit-scrollbar-track
				-webkit-box-shadow: inset 0 0 6px #cccccc
				background-color: #cccccc
			&::-webkit-scrollbar-thumb
				background-color: red
			@media screen and ( max-width:1024px)
				height: 330px
	.wrap-center
		.btn-primary
			height: 40px
			@apply w-full
	.cart-item
		@apply flex justify-start items-start bg-white py-2 relative
		border-bottom: 1px solid #ebebeb
		height: 120px
		&:first-child
			@apply pt-0
		*
			@apply mb-0
		.image
			a
				@apply block
				width: 85px
				img
					width: 100%
					height: 100%
					object-fit: scale-down
		.caption
			@apply pl-2
			@apply flex justify-between items-center
			.bottom
				@apply  mt-0
			.title
				@apply pr-8
				a
					@apply   mb-0 leading-tight font-medium transition
					font-size: clamp(12px,r(14px),14px)
					+line(2)
			.price
				@apply   font-bold my-1
				font-size: clamp(13px,r(15px),15px)
			.quantity-wrapper
				@apply   font-light whitespace-nowrap flex
				font-size: clamp(12px,r(15px),15px)
				.amount
					@apply   font-light pl-1
					font-size: clamp(12px,r(15px),15px)
	.sum-price
		p
			@apply  mb-0 font-medium
			font-size: clamp(12px,r(15px),15px)
			span
				@apply font-semibold
				font-size: clamp(12px,r(15px),15px)
	.item-quantity
		.input-group
			height: 30px
		.input-group-btn
			height: 30px
			input
				height: 30px
.cart-success-popup 
	@apply fixed top-0 right-0  px-4 flex py-1 pt-2  opacity-0 transition pointer-events-none
	z-index: 9999
	&.is-active
		@apply opacity-100 
	@media screen and ( max-width: 1024px)
		top: 60px
	em,p,span
		@apply text-white
	em
		font-size: 20px
		height: 20px
		line-height: 20px
		@apply inline-block mr-2
		@media screen and ( max-width: 1024px)
			font-size: 16px
			height: 16px
			line-height: 16px
	p
		@apply  font-semibold
		font-size: clamp(14px,r(16px),16px)
		* 
			@apply  font-semibold
			font-size: clamp(14px,r(16px),16px)
		.number
			@apply inline-block px-1
.empty-cart-wrapper
	@apply flex flex-col justify-center items-center py-4
	.text
		@apply  font-normal mb-4  text-center
		font-size: clamp(14px,r(16px),16px)
	.btn-center
		.btn
			em
				height: 14px !important
				line-height: 14px !important
			span
				color: #fff !important
			&:hover
				em
					transform: none !important
				span
					color: red !important
.cart-display
	padding: 60px 0
	.note-message 
		@apply   font-medium pb-4 text-right
		font-size: clamp(14px,r(16px),16px)
		&:before
			content: '*'
			@apply text-red mr-2
			display: inline-block
			pointer-events: none
			z-index: 9
			height: 14px
			width: 14px
			transition: .3s all ease-in-out
	.empty-cart-text
		@apply flex flex-col justify-center items-center py-16
		.cart-button-checkout
			@apply mt-6
			@screen lg
				@apply mt-8
		.checkout-title
			border: none !important
			.fas
				display: none !important
	.cart-table-wrapper
		table
			width: 100%
			tr
				@media screen and ( max-width:  960px)
					border-bottom: 1px solid #e1e1e1
					
				td
					border-bottom: 1px solid #e1e1e1
					padding: 20px 0
					@media screen and ( max-width: 960px )
						display: block
						border-bottom: 0
						padding: 10px 0
					&:last-child
						@media screen and ( max-width:  960px)
							padding-bottom: 20px
							
					&:first-child
						width: 60%
						@media screen and ( min-width:  1024px) and ( max-width:  1280px)
							width: 50%
						@media screen and ( min-width:  960px) and ( max-width:  1024px)
							width: 40%
						@media screen and ( max-width:  960px)
							width: 100%
							padding-top: 20px
					

					&:nth-child(2),&:nth-child(3)
						@media screen and ( min-width:  1024px)
							vertical-align: top
	.quantity-wrap
		@media screen and ( max-width:  960px)
			display: flex
			justify-content: center
			.item-quantity
				@apply center-item
				width: 200px
	.discount-wrap
		@media screen and ( max-width:  960px)
			display: flex
			justify-content: flex-start
			
		.old-price
			color: #333
			font-size: r(14px)
			line-height: 14px
			text-decoration: line-through
			padding: 0 15px
			@media screen and ( max-width:  960px)
				padding-left: 0
				
		.discount
			border-left: 1px solid #a7a7a7
			font-size: r(14px)
			line-height: 14px
			color: #ed1c24
			padding: 0 15px
	.total-wrapper
		@media screen and ( max-width:  960px)
			display: flex
			flex-direction: column
			align-items: flex-start
			justify-content: center
		@media screen and ( max-width:  767px)
			align-items: center
			
		*
			text-align: right
			color: #333
			font-size: r(16px)
			font-weight: 400
		.current,.quantity
			margin-bottom: 5px
		.temp-total
			font-weight: 700
	.product-item
		display: flex
		justify-content: flex-start
		align-items: center
		@media screen and ( max-width:  960px)
			position: relative
			align-items: center
			flex-direction: column
		.caption
			@media screen and ( max-width:  767px)
				@apply mb-0
				
		.image
			margin-right: 20px
			@media screen and ( max-width:  767px)
				@apply m-0
				
			a
				display: block
				height: 90px
				max-width: 100px
				min-width: 100px
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: contain
		.title
			display: block
			margin-bottom: 5px
			@media screen and ( max-width:  960px)
				@apply mt-4
				
			a
				font-size: r(16px)
				color: #111111
		.remove-item
			display: flex
			justify-content: flex-start
			align-items: center
			@media screen and ( max-width:  960px)
				position: absolute
				top: 0
				right: 0
				
			*
			em,span
				color: #999999

			em
				font-size: 16px
				height: 16px
				line-height: 16px
				display: block
				margin-right: 5px
	.temp-price
		@apply mb-4
		.price-wrapper 
			@apply flex justify-end
			.text
				font-size: clamp(15px,r(20px),20px)
				color: #333
				display: inline-block
				padding-right: 30px
				@media screen and ( max-width: 576px)
					font-size: clamp(14px,r(16px),16px)
			.price-text
				font-size: 20px
				line-height: 20px
				height: 20px
				color: #333
				font-weight: 500
				width: 250px
				@apply text-right
				@media screen and ( max-width: 1024px)
					font-size: clamp(15px,r(18px),18px)
				@media screen and ( max-width: 576px)
					width: 180px
.checkout-section
	.btn-secondary
		em
			@apply mr-3

.checkout-wrapper
	border-radius: 10px
	box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.15)
	margin-bottom: 30px
	&.checkout-summary-cart
		.total-price-wrap
			margin-top: 20px
			display: flex
			justify-content: space-between
			align-items: center
			.text 
				font-size: r(14px)
				padding: 10px 0
				color: #333
				text-align: right
				font-weight: 700
			.number
				font-size: r(24px)
				font-weight: 700
				@apply text-red
		.temp-total-wrap
			.table-wrap
				padding: 10px 0
				border-bottom: 1px solid #e1e1e1
				table
					width: 100%
					tr
						td
							font-size: r(14px)
							padding: 10px 0
							color: #333
							text-align: right
							&:first-child
								text-align: left
								font-size: r(14px)
								font-weight: 700
								
		.item
			display: flex
			justify-content: space-between
			align-items: center
			border-bottom: 1px solid #e1e1e1
			padding: 14px 0
			.name
				font-size: r(14px)
				color: #111111
				padding-left: 15px
				a
					
					font-size: r(14px)
					color: #111111
			.content 
				.price,.quantity
					font-size: r(14px)
					color: #333333
					margin-bottom: 5px
				
				.total
					font-size: r(14px)
					font-weight: 700
			.image-wrap
				display: flex
				.name
					a
						max-width: 140px
						min-width: 140px
						display: -webkit-box
						-webkit-line-clamp: 3
						-webkit-box-orient: vertical
						overflow: hidden
				a
					display: block
					height: 70px
					max-width: 80px
					min-width: 80px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: contain
	&.checkout-dicount
		.notice-text
			font-size: r(14px)
			color: #999999
			margin-top: 20px
			@media screen and ( max-width:  1024px)
				margin-top: 10px
				
		.setting-control
			display: flex
			position: relative
			
			.coupon-apply-error
				@apply absolute  left-0 font-light text-red italic text-12
				bottom: -15px
			input
				height: 35px
				color: #333333
				display: flex
				justify-content: center
				align-items: center
				padding: 0 20px
				border-top-left-radius: 8px
				border-bottom-left-radius: 8px
				border-top: 1px solid  #e1e1e1
				border-bottom: 1px solid  #e1e1e1
				border-left: 1px solid  #e1e1e1
				@apply w-full
				@media screen and ( max-width:  1024px)
					width: 100%
					
			button
				width: 120px
				display: flex
				justify-content: center
				align-items: center
				border-top-right-radius: 8px
				border-bottom-right-radius: 8px
				font-size: r(16px)
				font-weight: 700
				color: white
				@apply  px-2
				@media screen and ( max-width:  1024px)
					width: 80px
					min-width: 80px
					
				
	&.checkout-shipping
		.shipping-option
			.services
				margin-top: 15px
				select
					padding: 0 25px
					height: 48px
					border: 1px solid #f1f1f1
					border-radius: 5px
					width: 100%
					display: flex
					justify-content: flex-start
					align-items: center
					text-align: left
					color: #999999
		.form-shipping
			padding: 0 0 0  30px
		.shipping-option
			position: relative
			.shipping-method
				position: relative
		.form-group
			position: relative
			margin-bottom: 20px
			input[type='radio']
				position: absolute
				top: 0
				left: 0
				right: 0
				bottom: 0
				width: 100%
				height: 100%
				opacity: 0
				z-index: 20
				&:checked ~ label
					&::before
						@apply text-red
						content: '\f192'
			label
				position: relative
				z-index: 10
				font-size: r(16px)
				color: #999999
				border-radius: 5px
				border: 1px solid #e1e1e1
				padding: 0 30px
				display: flex
				justify-content: flex-start
				align-items: center
				background: #fafafa
				height: 48px
				pointer-events: none
				&:before
					content: '\f111'
					font-family: "Font Awesome 6 Free"
					display: block
					position: absolute
					pointer-events: none
					left: -30px
					top: 50%
					transform: translateY(-50%)
					z-index: 9
					height: 18px
					width: 18px
					color: #999999
					transition: .3s all ease-in-out
	&.checkout-address
		.checkout-form
			.form-group
				margin-bottom: 20px
				position: relative
				label
					&.error
						position: absolute
						left: 0
						font-size: r(12px)
						@apply text-red
						bottom: -15px
						@media screen and ( min-width:  1024px)
							bottom: -20px
							left: 170px
						&::before
							display: none
				@media screen and ( min-width:  1024px)
					margin-bottom: 30px
					display: flex
					justify-content: space-between
					align-items: center
				
				.fa-exclamation-triangle
					position: absolute
					left: 0
					font-size: r(12px)
					@apply text-red
					bottom: -15px
					@media screen and ( min-width:  1024px)
						bottom: -20px
						left: 170px
					&::before
						display: none
				.select-chevron
					overflow: hidden
					position: relative
					width: 100%
					@media screen and ( max-width:  1024px)
						width: 100%
					&:before
						content: '\f078'
						font-family: "Font Awesome 6 Free"
						display: block
						position: absolute
						right:  15px
						top: 50%
						color: #999999
						transform: translateY(-50%)
						z-index: 1
						pointer-events: none
						font-size: 20px
					select
						-webkit-box-sizing: border-box
						-moz-box-sizing: border-box
						box-sizing: border-box
						-webkit-appearance: none
						-moz-appearance: none
						width: 100%
						@media screen and ( max-width:  1024px)
							width: 100%
						&::-ms-expand 
							display: none
				label
					width: 170px
					min-width: 170px
					font-size: r(16px)
					color: #333
					display: inline-block
					@media screen and ( max-width:  1024px)
						margin-bottom: 10px
						
					&.require
						&::after
							content: '*'
							display: inline-block
							font-size: r(16px)
							@apply text-red
				input[type='text'],select,textarea
					padding: 0 25px
					height: 48px
					border: 1px solid #f1f1f1
					border-radius: 5px
					width: 100%
					display: flex
					justify-content: flex-start
					align-items: center
					text-align: left
				input[type='text']::placeholder,textarea:placeholder
					font-size: r(16px)
					color: #999999
				textarea
					padding-top: 15px
					height: r(70px)
	.add-address
		padding-top: 30px
		@media screen and ( max-width:  1024px)
			padding-top: 10px
			
		span
			color: #333
			font-weight: 500
			font-size: r(16px)
		a
			font-size: r(16px)
			font-weight: 500
			text-decoration: underline
			display: inline-block
			margin-left: 4px
	.checkout-title
		font-size: r(24px)
		padding: 0 30px
		@apply  border-b  border-opacity-50
		display: flex
		justify-content: flex-start
		align-items: center
		height: 60px
		
		font-weight: 700
		.number-quantity
			font-weight: 700
			display: inline-block
			margin: 0 4px
	.content-wrapper
		padding: 20px 30px 40px 30px
	.note-text
		display: block
		margin-top: 10px
		font-size: r(14px)
		color: #999999
		@media screen and ( min-width:  1024px)
			position: absolute
			bottom: -20px
			left: 0
			bottom: -40px
			left: 170px
			
	
.checkout-information
	padding: r(60px) 0
.checkout-payment
	.form-payment
		padding: 0 0 0  30px
	.qradio
		margin-bottom: 20px
	
	.form-group
		position: relative
		margin-bottom: 20px
		input[type='radio']
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			width: 100%
			height: 100%
			opacity: 0
			z-index: 20
			&:checked ~ label
				&::before
					@apply text-red
					content: '\f192'
		label
			position: relative
			z-index: 10
			font-size: r(16px)
			color: #999999
			border-radius: 5px
			border: 1px solid #e1e1e1
			padding: 0 30px
			display: flex
			background: #fafafa
			min-height: 90px
			pointer-events: none
			transition: .3s all  ease-in-out
			border-radius: 5px
			@media screen and ( min-width:  576.1px)
				justify-content: flex-start
				align-items: center
			@media screen and ( max-width:  576px)
				flex-direction: column
				justify-content: center
				align-items: center
				padding: 0 20px 0 0
				
			&:before
				content: '\f111'
				font-family: "Font Awesome 6 Free"
				display: block
				position: absolute
				pointer-events: none
				left: -30px
				top: 50%
				transform: translateY(-50%)
				z-index: 9
				height: 18px
				width: 18px
				color: #999999
				transition: .3s all ease-in-out
		.image-wrapper
			padding: 0
			@media screen and ( max-width: 576px)
				border-right: 0
				
			a
				display: flex
				justify-content: center
				align-items: center
				transition: .3s all  ease-in-out
				border-right: 1px solid #ebebeb
				height: 90px
				max-width: 100px
				min-width: 100px
				width: auto
				@media screen and ( max-width:  576px)
					border-right: 0
					
				img
					max-width: 100%
					height: auto
			@media screen and ( max-width:  576px)
				a
					display: block
					height: 80px
					max-width: 80px
					min-width: 80px
					width: auto
					@apply px-4
					img
						width: 100%
						height: 100%
						object-fit: contain
		.content
			padding: 20px 0 20px 30px
			height: 100%
			@media screen and ( max-width:  576px)
				width: 100%
				padding-left: 20px
				text-align: center
			.title
				font-size: r(16px)
				color: #333
			.text
				font-size: r(16px)
				font-weight: 700
				color:  #111111
				text-transform: uppercase
				margin-bottom: 10px
			.table-wrap
				table
					width: 100%
					tr
						td
							color: #666666
							font-size: r(16px)
							@media screen and ( max-width:  576px)
								text-align: right
							
							&:first-child
								color: #333
								width: 140px
								@media screen and ( max-width:  576px)
									text-align: left
