.alert
	position: relative
	padding: .75rem 1.25rem
	margin-bottom: 1rem
	border: 1px solid transparent
	border-radius: .25rem
	display: block
	line-height: 1.25
	&.alert-danger 
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success 
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb


	
.login-box,.wrap-register,.wrap-recover,.wrap-resetpass
	max-width: 400px
	margin: 60px auto
	padding: 1.875rem
	background: #fff
	border-radius: 10px
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.03)

.login-logo,.pagetitle,.postname,.wrap-recover h1
	font-size: 1.5rem
	text-align: center
	line-height: 1.375
	font-weight: 700
	margin-bottom: 1.25rem

.posttitle, .wrap-resetpass h1
	font-size: 1.25rem
	margin-bottom: 1.25rem
	line-height: 1.375

.postname
	text-align: left

.login-box-msg
	font-size: 1.125rem
	margin-bottom: 1rem

.login-box,.wrap-register,.account-form,.address-form,.wrap-recover,.wrap-resetpass
	input[type=text],input[type=password],select
		border: 0 !important
		border-bottom: 1px solid #d7d7d7 !important
		padding: 12px!important
		width: 100% !important
		height: auto !important
	input[type=submit]
		height: 2.5rem
		width: 8.125rem
		font-size: 1rem
		color: #fff
		border: 0
		font-weight: 700
		display: block
		margin-left: auto
	input:disabled 
		color: #666
		background-color: transparent
		font-style: italic

.wrap-register,.account-form,.address-form,.wrap-recover,.wrap-resetpass
	.settingrow
		> *
			flex: 0 0 100%
			max-width: 100%
	.form-group
		margin-bottom: 20px
		label
			line-height: 1.5

.passwordrecovery,.wrap-register
	input[type=submit]
		margin: 0 auto

.fa-exclamation-triangle
	font-family: 'Nunito Sans', sans-serif !important
	font-size: 12px
	color: red
	line-height: 2
	&::before
		display: none
.profile-container
	padding: 3rem 15px

.user-sidebar,.user-sidelink
	padding: 1.875rem
	background: #fff
	border-radius: 10px
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.03)

.userinfo
	figure
		display: flex
		align-items: center
		img
			border-radius: 50%
		figcaption
			padding-left: 20px
			line-height: 1.375
	.user-name
		font-weight: 700
		font-size: 1.125rem

.user-sidelink
	margin-top: 1.875rem
	ul 
		li
			+ li 
				margin-top: 15px
				border-top: 1px solid #eee
				padding-top: 15px
			&.active
				a
					color: red

.account-info,.coin-info,.cart-history,.address-info,.order-info,.order-information,.order-product,.order-total
	padding: 1.25rem
	background: #fff
	border-radius: 10px
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.03)
	line-height: 1.375
	.link
		color: #5bc0de
		font-size: 0.875rem
		
.info-heading
	display: flex
	align-items: center
	justify-content: space-between
	margin-bottom: 1.25rem
	.posttitle,.postname
		margin-bottom: 0
	.link
		color: #5bc0de
		font-size: 0.875rem
		
.account-info
	.info-detail
		.group
			.label
				color: #787878

.address-list
	.address-col
		+ .address-col
			padding-top: 1.25rem
			margin-top: 1.25rem
			border-top: 1px dashed #eee
	.address
		font-size: 0.875rem
		position: relative
		.type
			align-items: center
			background-color: red
			border-radius: 4px
			color: #fff
			display: inline-flex
			font-size: .75rem
			height: 2em
			justify-content: center
			line-height: 1.5
			padding-left: .75em
			padding-right: .75em
			white-space: nowrap
			margin-bottom: .9375rem
			position: absolute
			right: 0
			top: 0
		.btn-editaddr
			color: #5bc0de
			text-decoration: underline
			text-underline-position: under
			display: inline-block
			margin-top: 0.625rem
		.btn-deleteaddr
			color: #999
			margin-left: 0.625rem


.cart-history,.order-product
	overflow: auto
	table
		width: 100%
		font-size: 14px
		thead
			th
				font-weight: 700
				padding: .75rem .75rem
				border-bottom: 1px solid #dbdbdb
		tbody
			td
				border: 1px solid #dbdbdb
				border-width: 0 0 1px
				padding: .75rem .75rem
				vertical-align: top
				a
					display: block
					color: #5bc0de
					
					+ .name
						margin-top: 5px
					&:hover
						text-decoration: underline
			tr
				&:last-child
					td
						border-bottom: 0
.order-product
	table
		td
			text-align: center
			.product
				display: flex
				align-items: center
				.img
					width: 80px
				.caption
					flex: 1 1 0%
					padding-left: 20px
					font-size: 14px

.order-total
	width: 400px
	max-width: 100%
	margin-left: auto
	margin-top: 1rem
	font-size: 14px
	table
		width: 100%
		tr
			td
				text-align: right
				padding: .5rem .75rem
				&.total
					font-weight: 700
					font-size: 18px
					color: red
			&:first-child
				td
					padding-top: 0

.regcomplete
	span
		color: #6cc070
		display: block
		text-align: center
		margin-bottom: 20px

.order-information
	.title
		font-weight: 700
		font-size: 18px
		margin-bottom: 15px
		padding-bottom: 10px
		border-bottom: 1px dashed #ccc
	ul
		font-size: 14px
		li
			&.name
				font-size: 16px
.order-detail-wrap
	.order-note
		@apply my-4 p-4 mt-16
		+boxshadow
		.note-title-wrapper
			@apply text-16 font-bold
	.postname
		padding-left: 15px
	.cart-display
		border: none
		.product-item
			box-shadow: none
		.btn-primary
			@media screen and ( max-width:  1024px)
				@apply my-2
			span
				@apply text-16 font-medium
		.cart-wrapper
			box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05)
			@apply p-8 bg-white
		.total-wrapper
			@apply flex justify-center items-end flex-col pt-8
			.total-table
				@media screen and ( max-width:  1024px)
					@apply w-full
					
				tr
					&:nth-child(2)
						td
							&:last-child
								@apply font-normal
					&:last-child
						td
							&:last-child
								@apply text-red
				td
					@apply text-16 font-bold  py-2
					&:last-child
						@apply pl-16
						@screen lg
							@apply pl-32
			.btn-right
				@apply pt-12
				@media screen and ( max-width: 767px)
					@apply flex flex-col justify-center items-center w-full
		.carttable
			@apply w-full
			border: 1px solid #f0f0f0
			+boxshadow
			thead
				@media screen and ( max-width:960px  )
					@apply hidden
				th
					background: #f0f0f0
					height: 50px
					@apply text-16  uppercase font-medium px-4 text-center py-3 normal-case
					&:first-child
						@apply text-left pl-8
		
			tr
				&:nth-child(even)
					td
						background: #f2f2f2
				&:last-child
					td
						@apply pb-8
						@media screen and ( max-width:  960px)
							@apply pb-6
							&:last-child
								@apply pb-8
				td
					@apply px-6 pt-8 pb-4 font-medium
					width: 15%
				
					&:first-child
						width: 55%
				@media screen and ( max-width:  960px)
					@apply flex flex-col
					flex-wrap: wrap
					td
						@apply block py-0 pb-4
						width: 100%
						&:first-child
							width: 100%
							@apply pt-6
						&:nth-child(3),&:nth-child(4)
							@apply inline-block
						&:last-child
							@apply pb-6
							
						
		.product-item
			@apply flex flex-row justify-start items-center mb-0
			.image
				a
					display: block
					height: 120px
					max-width: 120px
					min-width: 120px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: contain
			.caption
				@apply ml-4
				.title
					@apply mb-0
					a
						@apply text-16  block mb-1 font-medium whitespace-normal
						max-width: 200px
				.remove-item
					@apply flex items-start justify-start
					em
						@apply text-red block mr-2
						font-size: 16px
						height: 16px
					span
						@apply  text-16 font-medium
		.price
			@apply flex items-end justify-center flex-col
			@media screen and ( max-width:  960px)
				@apply items-start
				.current
					@apply pl-2
			.current
				@apply text-18  mb-2 font-medium
			.old-wrap
				@apply flex
				.old
					@apply text-16  font-medium line-through whitespace-nowrap
				.discount
					@apply text-red text-16 font-medium whitespace-nowrap
				span 
					@apply px-2 font-medium
					&:not(:last-child)
						border-right: 1px solid #e1e1e1
					&:last-child
						@apply pr-0
		.total
			@apply text-16  font-bold text-center
			@media screen and ( max-width:  960px)
				@apply text-left
				
		.quantity-wrapper  
			@media screen and ( max-width:  1024px)
				@apply pt-0 pb-2
				
			.item-quantity 
				height: 40px
				border-radius: 5px
				@apply center-item
				input
					height: 35px
				.input-group-btn
					height: 38px
			.qty-minus,.qty-plus
				background: #e6e6e6
				&:hover
					background: none
			.qty-minus
				border-top-left-radius: 5px
				border-bottom-left-radius: 5px
			.qty-plus
				border-top-right-radius: 5px
				border-bottom-right-radius: 5px
	.user-page
		.full-width-row
			width: 100% !important
	.full-width-row
		margin-top: 30px
	.admin-title
		margin-bottom: 30px
	.second-row-status
		.title
			@apply mb-4
	.cart-display
		.wrapper
			@apply overflow-x-auto
			table
				@apply  w-full whitespace-nowrap

.order-detail
	.cart-display
		@apply bg-white pt-0 mt-16 pb-0
		.wrapper
			@media screen and ( max-width: 767px)
				@apply whitespace-normal
		.product-item 
			.caption
				@apply mb-0
	.bottom-wrap
		@apply flex justify-between items-center mt-4
	.admin-title
		@apply p-4 py-6
		+boxshadow
		.text
			strong
				@apply text-red
	.btn-back
		@apply pt-4 pl-0
		&:hover
			span
				@apply underline
	.status
		span
			@apply font-medium
	.title
		@apply text-16  font-bold uppercase mb-2
	.item
		@apply h-full py-8 px-5 bg-white
		+boxshadow
		ul
			li
				@apply mb-1 block font-medium
				p
					@apply text-15 font-bold mb-1 
				span
					@apply text-15 font-normal 
				.status
					@apply pt-2
					&.done
					&.pending
						@apply text-red
	.carttable
		border: none
		thead
			th
				@apply text-16 font-bold whitespace-nowrap
				@media screen and ( max-width: 1024px)
					@apply whitespace-normal
					
		tr
			td
				@apply text-16 
				&:nth-child(3)
					@apply text-center
					@media screen and ( max-width: 1024px)
						@apply text-left
						
		@apply p-8
		span
			@apply whitespace-nowrap
	.total-wrapper
		@apply pr-5 py-5
		@media screen and ( max-width: 1024px)
			@apply p-5
			
		.total-table
			td
				@apply text-18
	.product-item
		.caption
			.title
				a
					@apply normal-case
.navigation-wrapper
	@apply gap-item 
	@media screen and ( max-width: 576px)
		@apply flex-col
		align-items: flex-end
	.btn-left
		@media screen and ( max-width: 576px)
			@apply pb-8 w-full
	.temp-total-price
		margin-bottom: 15px
		.price-wrapper
			@apply flex justify-end
			.text
				font-size: 20px
				color: #333
				display: inline-block
				padding-right: 30px
				@media screen and ( max-width: 576px)
					@apply text-16
			.price-text
				font-size: 30px
				line-height: 30px
				height: 30px
				font-weight: 700
				width: 250px
				@apply text-right text-red
				@media screen and ( max-width: 576px)
					@apply text-24
				@media screen and ( max-width: 576px)
					width: 180px
	.vat-text
		margin-bottom: 15px
		text-align: right
		font-size: r(14px)
		line-height: 14px
		height: 14px
		color: #666666
		font-style: italic
	.wrap-right
		@apply col-ver