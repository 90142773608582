.service-detail
	.zone-title
		@apply mb-3
	.block-title
		@apply mb-2
	.block-wrap
		@apply py-8
		@screen lg
			@apply py-10
	.scollbar-wrap
		@screen lg
			height: 140px
	.img
		a
			+img-ratio(504,672)
	.txt-hor
			@screen lg
				@apply pr-6
	.row
		&:not(:last-child)
			@apply mb-0
			@screen lg
				@apply mb-12
		&:nth-child(even)
			@apply flex-row-reverse
			.txt-hor
				@screen lg
					@apply pl-6 pr-0
			
.service-contact 
	.bg-wrap
		@apply rad-5 bg-white p-5
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
		@screen lg
			@apply p-7
	.img
		a
			+img-ratio(532,416)
.other-service
	.service-item
		.img
			a
				+img-ratio(1,1)
	