.wrap-form
	@apply relative
	.row
		box-shadow: none !important
	.fa-exclamation-triangle
		@apply absolute font-light text-red
		font-size: 12px
		font-family: 'Montserrat', sans-serif
		bottom: -20px
		left: 15px
		@media screen and ( max-width: 576px)
			font-size: 10px
			@apply font-bold
	.col
		@apply mb-4 relative
		&:last-child
			@apply mb-6
	.form-group
		@apply relative 
		margin-bottom: 30px !important
		@media screen and ( max-width: 576px)
			margin-bottom: 25px !important
		input[type='text'],textarea,select
			height: 44px
			@apply text-neutral-500 bg-neutral-100  border-opacity-20 px-5 rounded-[5px]
			+fz(14px,16px)
			@media screen and ( max-width: 1200px)
				height: 40px
			@screen lg
				@apply px-6
			&::placeholder
				@apply  font-normal text-neutral-500
				+fz(14px,16px)
	.form-group
		&.form-select
			@apply relative overflow-hidden
			&::after
				content: ''
				@apply block absolute-y pointer-events-none right-[35px]  z-10  transition
				width: r(24px)
				height: 11px
				background-size: contain
				background-repeat: no-repeat
				background-position: center
				background-image: url("data:image/svg+xml;charset=utf-8,<svg width='23' height='11' viewBox='0 0 23 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.1875 0.53125C1.32812 0.34375 1.51562 0.25 1.75 0.25C1.89062 0.25 2.07812 0.296875 2.21875 0.4375L11.4531 8.92188L20.7344 0.4375C21.0156 0.15625 21.4844 0.15625 21.7656 0.484375C22.0469 0.765625 22.0469 1.23438 21.7188 1.51562L11.9688 10.5156C11.6875 10.7969 11.2656 10.7969 10.9844 10.5156L1.23438 1.51562C0.90625 1.28125 0.90625 0.8125 1.1875 0.53125Z' fill='%232F4394'/></svg>")
				@media screen and ( max-width: 1200px)
					width: 16px
					height: 11px
					height: 40px
			select
				height: 44px
				@apply rounded-none w-full   
				padding-right: 50px !important
				-webkit-appearance: none
				-moz-appearance: none
				-webkit-box-sizing: border-box
				-moz-box-sizing: border-box
				box-sizing: border-box
				-webkit-appearance: none
				-moz-appearance: none
				+fz(14px,16px)
				@media screen and ( max-width: 1200px)
					height: 40px
				option
					+fz(14px,16px)
				&::-ms-expand 
					display: none
		textarea
			@apply p-4 w-full
			height: 7rem
			@screen lg
				height: 100%
	.frm-btnwrap
		@apply end-item w-fit mt-6 absolute bottom-0
		right:  0
		@media screen and ( max-width: 1200px)
			@apply mt-8
			margin-bottom: 0 !important
		@media screen and ( max-width: 1024px)
			@apply ml-4
		label
			display: none !important
		.frm-btn
			@apply relative
			&:hover
				&::after
					@apply text-secondary-400
				input[type='submit']
					@apply  text-secondary-400 bg-white border-secondary-400
			&::after
				font-family: 'icomoon' !important
				content: "\e900"
				@apply block absolute pointer-events-none top-50 translate-y-50 right-[25px]  z-10  transition font-normal text-white
				height: 18px
				width: 20px
				line-height: 18px
				font-size: clamp(18px,r(20px),20px)
				@media screen and ( max-width: 1024px)
					right: 40px
			input[type='submit']
				width: 160px
				height: 44px
				@apply transition font-medium center-item px-6 pr-12 uppercase bg-secondary-400 rounded-full text-white border border-secondary-400
				+fz(14px,16px)
				@media screen and ( max-width: 1024px) 
					height: 40px
					@apply px-3  pr-8

.frm-btn-reset
	display: none
.frm-captcha
	margin: 40px 0 0  0 !important
	display: inline-flex
	flex-direction: row-reverse
	img
		height: 44px !important
		max-width: auto
		max-width: 150px
		@media screen and ( max-width:1200px)
			max-width: 150px
			
	@media screen and (max-width:576px)
		flex-direction: column-reverse
	.frm-captcha-input
		@apply mt-0
		margin-left: 50px
		@media screen and ( max-width:576px)
			margin-left: 0
		label
			display: none
		input
			padding: 0 10px
			margin-top: 0
			height: 30px
			@media screen and ( max-width:1200px)
				max-width: 200px
			@media screen and ( max-width:1200px)
				max-width: 150px
				
			@media screen and ( max-width:1024px)
				max-width: 200px
				
			@media (max-width: 767.98px)
				margin-left: 20px
			@media (max-width: 576.98px)
				margin-left: 0
	.rcRefreshImage
		position: absolute
		opacity: 1
		cursor: pointer
		height: 0
		width: 0
		right: -5px
		top: 5px
		color: #ffffff
		z-index: 0
		font-size: 0
		&:before
			font-family: 'Font Awesome 6 Pro'
			position: absolute
			top: 0
			right: -40px
			opacity: 1
			z-index: 99
			color: #9d9080
			font-size: 30px
			content: "\f01e"
			display: flex
			justify-content: center
			align-items: flex-start
			@media screen and ( max-width:576px)
				height: 50px
@media screen and ( max-width:500px )
	#ctl00_mainContent_ctl05_captcha_ctl00
		display: none
#ctl00_mainContent_ctl02_captcha_ctl01
	position: relative
#ctl00_mainContent_ctl06_btnReset,#ctl00_mainContent_ctl05_captcha_ctl00,#ctl00_mainContent_ctl08_captcha_ctl00,#ctl00_mainContent_ctl07_captcha_ctl00
	display: none
#ctl00_mainContent_ctl06_txtCaptcha
	margin-left: 10px
#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton
	#text
		display: none
.RadCaptcha
	@apply relative
	@media screen and ( max-width:767.5px)
		margin-left: 0
	span
		@apply hidden
	#ctl00_mainContent_ctl04_captcha_ctl00
		display: block !important
		position: absolute
		left: 0
		top: 100%
		@apply text-red text-12
	>div
		>div
			display: flex
			position: relative
			flex-direction: column-reverse
			height: 40px !important
			margin-top: 0
			@media (max-width: 767.98px)
				margin-right: 0
				width: 180px


.form-apply
	
	iframe
		width: 100%
		height: 410px
		@screen xl
			@apply w-full
#ctl00_phMain_jobApply_upContact
	margin-bottom: 0 !important
.recruit-container
	padding: 0 !important
.recruitment-modal
	max-width: 860px !important
	padding: r(50px) r(20px) 0 r(20px) !important
	.wrap-right
		.form-group
			@apply h-full
			textarea
				height: 100% !important
.half-gird
	@screen md
		@apply flex w-full
.apply-frm
	margin: 0 !important
	overflow: hidden
	background: #fff !important
	width: 100%
	height: 100%
	@media screen and ( max-width:  576px)
		overflow: hidden
		width: 100%
	.grid
		@apply w-full
	.wrap-bottom
		@apply flex justify-between
		@media screen and ( max-width: 768px)
			@apply flex-col
	.wrap-left
		@screen md
			@apply w-full
	.wrap-right
		@apply h-full
		@screen md
			@apply w-full
		.wrap-form 
			.form-group
				margin-bottom: 0 !important
			.frm-btnwrap
				position: static !important
		textarea
			@screen lg
				@apply h-full
	.recruitment-container
		min-width: 860px !important
	.frm-captcha
		@apply mb-6
		display: flex 
		flex-direction: row !important
		margin-top: 0 !important
		width: fit-content !important
		@media screen and ( max-width: 768px)
			flex-direction: column !important
		@screen lg
			@apply absolute right-0  pr-8
			bottom: 40px
			width: auto !important
		.RadCaptcha > div > div
			width: fit-content !important
		.frm-captcha-input
			@media screen and ( max-width: 768px)
				margin-bottom: 20px !important
			@media screen and ( min-width: 768px)
				margin: 0 50px 0 0 !important
		.rcRefreshImage
			@media screen and ( min-width: 768px)
				left: -50px !important
	.wrap-form
		@apply relative flex-col
		@screen lg
			height: auto
			padding: r(30px)
		.form-group
			input[type='text'],textarea,select
				height: 44px
				border: 1px solid #E7E7E7 !important
			textarea
				height: 270px
				@media screen and ( max-width: 768px)
					height: 90px
		.frm-btnwrap
			position: static !important
			@apply start-item w-auto
			margin-top: 0 !important
			margin-right: 15px !important
			
	h4
		@apply hidden
	input[type="file"]
		left: 0
	>div
		width: 100%
	.RadUpload_Simple 
		width: 100%
		height: 100%
		input
			width: 100%
			height: 100%
	.RadUpload 
		width: 100%
		height: 100%
		pointer-events:  auto
		.ruButton
			@apply text-16 uppercase opacity-0
			font-family: 'Nunito Sans', sans-serif
		.ruInputs
			width: 100%
			position: relative
		.ruRemove
			position: absolute
			top: 50px
			right: 30px
			opacity: 1
			font-size: 12px
			color: #ff0000
			width: auto !important
			display: block
			height: 20px !important
		.ruBrowse
			@apply hidden
	#ctl00_phMain_jobApply_pnlNewComment
		position: relative
		display: flex
		flex-wrap: wrap
		>.form-group
			width: 100%
			margin-top: -10px
			position: relative
		.col-left,.col-right
			flex: 0 0 100%
			max-width: 100%
			display: flex
			flex-wrap: wrap
			>.form-group
				margin-bottom: 30px
			@media (min-width: 576px)
				margin: 0 -10px
				>.form-group
					padding: 0 10px
			
	.col-left
		>.form-group
			flex: 0 0 100%
			max-width: 100%
			@media (min-width: 576px)
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4)
					flex: 0 0 50%
					max-width: 50%
	.col-right
		>.form-group
			flex: 0 0 100%
			max-width: 100%
	.fa-exclamation-triangle
		@apply absolute font-normal 
		bottom: -22px
		left: 20px
		color: #f30000
		font-size: 12px
	.label
		display: none
		font-size: 14px
		font-weight: 400
		color: #999999
		margin-bottom: 4px
		.required
			color: red
	
	textarea
		height: 90px
		@screen xl
			height: 130px
	.RadAsyncUpload
		.ruDropZone
			display: none
		.ruFileWrap
			position: static
			display: block
			height: auto !important
			max-height: 50px
			.ruUploadProgress 
				position: absolute
				top: 50px
				left: 15px
		.ruFileInput, .rugreyse
			height: 50px
			width: 120px
			height: 50px
			text-transform: uppercase
			font-size: 15px
			display: flex
			justify-content: center
			align-items: center
			border: 1px solid #333
			background: #333
			height: 50px
			width: 250px
			&:hover
				background: white !important
				color: #333 !important
		.ruFileInput
			cursor: pointer
			border: 1px solid #333
			pointer-events: auto
			top: 0 !important
			left: 0
			padding: 0 !important
			bottom: 10px
			position: absolute
			opacity: 0
			height: 40px
			width: 100% !important
			cursor: pointer
			&:hover
				background: white !important
				color: #333 !important
	.form-group-btn
		@media screen and ( max-width: 1024px)
			@apply start-item mt-6
		@screen lg
			@apply absolute right-[20px] bottom-0

	.attachfile1
		@apply block relative 
		.left
			@apply absolute top-0 left-0 bottom-0 right-0 w-full h-full  cursor-pointer pointer-events-none
			z-index: 99
		label
			@apply start-item  font-normal  relative pointer-events-none text-white 
			background: #F3F3F3 !important
			color: #7c7c7c !important
			+fz(15px,16px) 
			font-weight: 500 !important
			margin-top: 0
			height: 44px
			@media screen and ( max-width: 1024px)
				@apply w-full px-3
				word-break: break-normal 
			*
				color: #7c7c7c !important
				+fz(15px,16px) 
				font-weight: 500 !important
			input[type='file']
				@apply w-full cursor-pointer h-full
			input[type='file']::file-selector-button
				cursor: pointer
			input[type='file']::-webkit-file-upload-button 
				cursor: pointer
			::-webkit-file-upload-button 
				cursor: pointer
	.attachfile2
		display: none
	.form-group 
		position: relative
		&.disable-input
			input
				@apply pointer-events-none
				background: #F1F1F1
				border: none