.news-detail
	.social-wrap
		@media screen and ( max-width: 1024px)
			@apply start-item pt-5
		@screen lg
			@apply absolute top-0 right-full mr-5 col-hor
		a
			@apply mb-3
	.bottom-wrap
		@apply pb-4 mb-4 border-b border-b-primary-400
.other-news
.news-detail-page,.recruitment-detail-page
	.top-banner
		@apply hidden
.news-list-page
	.modulepager
		padding-bottom: r(70px)
	.service-contact
		@apply hidden